import { AiFillWindows, AiOutlineSearch } from "react-icons/ai";
import { MdFlourescent, MdRoofing, MdKitchen } from "react-icons/md";
import { MdMailOutline, MdHouseSiding } from "react-icons/md";
import { GiFloorPolisher, GiLeak } from "react-icons/gi";
import {
  FaBath,
  FaCarAlt,
  FaHandHoldingMedical,
  FaHouseDamage,
} from "react-icons/fa";
import { BsDoorOpenFill } from "react-icons/bs";
import { WiSolarEclipse } from "react-icons/wi";

//

export const Publiclinks = [
  {
    id: 0,
    name: "Zipcode Search",
    path: "/",
    icon: <AiOutlineSearch size="20" />,
    child_routes: null,
  },
  {
    id: 1,
    name: "Roofing",
    path: "/Roofing",
    icon: <MdRoofing size="20" />,
    child_routes: null,
  },
  {
    id: 2,
    name: "Window",
    path: "/window",
    icon: <AiFillWindows size="20" />,
    child_routes: null,
  },
  {
    id: 3,
    name: "Kitchen",
    path: "/kitchen",
    icon: <MdKitchen size="20" />,
    child_routes: null,
  },

  {
    id: 4,
    name: "Flooring",
    path: "/flooring",
    icon: <GiFloorPolisher size="20" />,
    child_routes: null,
  },

  {
    id: 5,
    name: "Bathroom",
    path: "/bathroom",
    icon: <FaBath size="20" />,
    child_routes: null,
  },

  {
    id: 6,
    name: "Siding",
    path: "/siding",
    icon: <MdHouseSiding size="20" />,
    child_routes: null,
  },

  {
    id: 7,
    name: "Ever Live",
    path: "/vert",
    icon: <MdFlourescent size="20" />,
    child_routes: null,
  },

  {
    id: 8,
    name: "inq-posting",
    path: "/inq-posting",
    icon: <MdMailOutline size="20" />,
    child_routes: null,
  },
  // {
  //   id: 9,
  //   name: "Medicare",
  //   path: "/medicare",
  //   icon: <MdOutlineMedicalServices size="20" />,
  //   child_routes: null,
  // },
  {
    id: 10,
    name: "solar",
    path: "/solar",
    icon: <WiSolarEclipse size="20" />,
    child_routes: null,
  },

  // {
  //   id: 10,
  //   name: "Plumbing",
  //   path: "/plumbing",
  //   icon: <BiTargetLock size="20" />,
  //   child_routes: null,
  // },

  // {
  //   id: 13,
  //   name: "Solar",
  //   path: "/solar",
  //   icon: <BiTargetLock size="20" />,
  //   child_routes: null,
  // },

  {
    id: 14,
    name: "Gutter",
    path: "/gutter",
    icon: <GiLeak size="20" />,
    child_routes: null,
  },

  {
    id: 111,
    name: "HVAC",
    path: "/hvac",
    icon: <BsDoorOpenFill size="20" />,
    child_routes: null,
  },
  {
    id: 15,
    name: "Medicare2",
    path: "/medicare2",
    icon: <FaHandHoldingMedical size="20" />,
    child_routes: null,
  },
  {
    id: 16,
    name: "settlement",
    path: "/settlement",
    icon: <FaHandHoldingMedical size="20" />,
    child_routes: null,
  },
  {
    id: 16,
    name: "Home warranty",
    path: "/home",
    icon: <FaHandHoldingMedical size="20" />,
    child_routes: null,
  },
  {
    id: 16,
    name: "Auto Insurance",
    path: "/auto",
    icon: <FaCarAlt size="20" />,
    child_routes: null,
  },
  {
    id: 16,
    name: "Home Insurance",
    path: "/home-insurance",
    icon: <FaHouseDamage size="20" />,
    child_routes: null,
  },
  // {
  //   id: 16,
  //   name: "Water Damage",
  //   path: "/water-damage",
  //   icon: <BiTargetLock size="20" />,
  //   child_routes: null,
  // },

  //
];
